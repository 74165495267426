import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ToSPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <h1>CocktailMeister ToS</h1>
    <p>TBD</p>
    <p>Please visit again! We are working on our Terms of Services.</p>
  </Layout>
);

export default ToSPage;
